<template>
  <div class="in-orders p-3 bg-white   box-shaddow20 overflow-auto">
   <div id="orderPageHeader">
    <h4 class="text-center ">لیست سفارشات</h4>
    <div :class="{ skeleton: !orders && status }" class="mainTabSection pb-3 flex-wrap">
      <template >
        <b-skeleton v-if="!orders && status" type="button"></b-skeleton>
        <button
        v-else
        @click="showOrders(1, 'current')"
        class="statusBtn text-color-666 px-4 mr-3 border bg-none   fontsize14
            "
          :class="{ 'order-tracking-active': orderTrackingTab === 1 }"
        >
          جاری
        </button>
        <b-skeleton v-if="!orders && status" type="button"></b-skeleton>
        <button
          v-else
          @click="showOrders(2, 'delivered')"
          class=" statusBtn text-color-666 px-4 mr-3 border bg-none   fontsize14"
          :class="{ 'order-tracking-active': orderTrackingTab === 2 }"
        >
          تحویل شده
          <!-- <span
            v-if="statistics != null && statistics.new != 0"
            class="statistics rounded-circle bg-color-themeRed text-white p-1 fontsize12 weight-bold"
          >
            {{ statistics.new }}</span
          > -->
        </button>
        <b-skeleton v-if="!orders && status" type="button"></b-skeleton>
        <button
        v-else
        @click="showOrders(3, 'canceled')"
        class=" statusBtn text-color-666 px-4 mr-3 border bg-none   fontsize14"
        :class="{ 'order-tracking-active': orderTrackingTab === 3 }"
        >
        لغو شده
        <!-- <span
        v-if="statistics != null && statistics.delivered != 0"
        class=" statistics rounded-circle bg-color-themeRed text-white p-1 fontsize12 weight-bold"
        >
        {{ statistics.delivered }}</span
        > -->
      </button>
    </template>
 
    </div>
   </div>
    <LineGrow open-width="90%" />

    <section id="orderSection" v-if="tab === 3">
      <template v-if="status">
        <hr />
        <div class="skeleton">
          <b-skeleton v-if="status" type="button"></b-skeleton>

          <b-skeleton v-if="status" type="button"></b-skeleton>

          <b-skeleton v-if="status" type="button"></b-skeleton>

          <b-skeleton v-if="status" type="button"></b-skeleton>

          <b-skeleton v-if="status" type="button"></b-skeleton>

          <b-skeleton v-if="status" type="button"></b-skeleton>
        </div>
        <hr />
        <b-skeleton></b-skeleton>
        <hr />
        <b-skeleton></b-skeleton>
        <hr />
        <b-skeleton></b-skeleton>
        <hr />
        <b-skeleton></b-skeleton>
      </template>
      <template v-else>
        <div id="tabelBox">
          <!-- همه سفارشات -->
          <div v-if="orders.data.length != 0" id="ordersDetail">
            <article     v-for="(order, index) in orders.data"
                  :key="index" class="orderDetail">
              <p class="headerOrder">
                <OrderStatus :status="order.status" />
                <router-link :to="{ name: 'order', params: { id: order.id } }">
                  <ZNextSvg />
                </router-link>
              </p>
                <ul  class="mainOrderContent">
                  <li class="opacity06">
                    {{
                    new Date(order.created_at).toLocaleDateString("fa")
                  }}
                  </li>
                  <li>
                    <span class="opacity06">کد سفارش</span>
                    <span class="opacity1">
                      {{order.id}}
                    </span>
                  </li>
                  <li>
                    <span class="opacity06">
                   
مبلغ
                    </span>
                    <span class="opacity1">
                    <template v-if="order.shipping_amount == null"
                      >رایگان</template
                    >
                    <template v-else>{{
                      order.shipping_amount | price
                    }}</template>
                    </span>
                    <span v-if="order.shipping_amount != null">
                      تومان
                    </span>
                  </li>
                  <li>
                    <span class="opacity06">
تخفیف
                    </span>
                    <span class="opacity1">
                    <template v-if="order.discount_amount == 0"
                      >----- </template
                    >
                    <template v-else>{{
                      order.discount_amount | price
                    }}</template>
                    </span>
                      <span v-if="order.discount_amount != 0"> 
                      تومان
                    </span>
                  </li>
                </ul>
               
              <router-link   :to="{ name: 'order', params: { id: order.id } }" class="orderImagesSlider" >
                <template  v-for="(item, index) in order.items">

                  <img v-if="item.product.images.length" :src="item.product.images[0].url"    
                  :key="index" :alt="item.product.title" :title="item.product.title">
                </template>
              </router-link>
              <p class="footerContent">
                <router-link :to="{ name: 'order', params: { id: order.id } ,query:{showPrint:1}}">
                  <svg version="1.0" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 24 24" enable-background="new 0 0 24 24" xml:space="preserve">
<g>
	<path d="M23.1,0.6c-0.5-0.4-1-0.6-1.6-0.6v0h-16C4.7,0,4,0.7,4,1.5V19H1.5C0.7,19,0,19.7,0,20.5v1C0,22.9,1.1,24,2.5,24h15
		c0.2,0,0.3,0,0.4,0c1.2-0.2,2.1-1.3,2.1-2.6V9h2.5C23.3,9,24,8.3,24,7.5v-5C24,1.8,23.7,1.1,23.1,0.6z M2.5,23
		C1.7,23,1,22.3,1,21.5v-1C1,20.2,1.2,20,1.5,20h13c0.3,0,0.5,0.2,0.5,0.5v1c0,0.5,0.2,1.1,0.5,1.5H2.5z M19,2.6v18.8
		c0,0.8-0.5,1.5-1.2,1.6c-0.1,0-0.2,0-0.3,0h0c-0.3,0-0.7-0.1-0.9-0.4c-0.3-0.3-0.5-0.7-0.5-1.1v-1c0-0.8-0.7-1.5-1.5-1.5H5V1.5
		C5,1.2,5.2,1,5.5,1h14C19.2,1.4,19,2,19,2.6z M23,7.5C23,7.8,22.8,8,22.5,8H20V2.6c0-0.8,0.5-1.5,1.2-1.6c0.5-0.1,0.9,0,1.2,0.3
		C22.8,1.6,23,2.1,23,2.5V7.5z"/>
	<path d="M16.5,14c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h1c0.3,0,0.5-0.2,0.5-0.5S17.8,12,17.5,12H17v-0.5c0-0.3-0.2-0.5-0.5-0.5
		S16,11.2,16,11.5v0.6c-0.6,0.2-1,0.8-1,1.4c0,0.8,0.7,1.5,1.5,1.5c0.3,0,0.5,0.2,0.5,0.5S16.8,16,16.5,16h-1
		c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5H16v0.5c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5v-0.6c0.6-0.2,1-0.8,1-1.4
		C18,14.7,17.3,14,16.5,14z"/>
	<path d="M7.5,5C7.2,5,7,4.8,7,4.5S7.2,4,7.5,4h1C8.8,4,9,3.8,9,3.5S8.8,3,8.5,3H8V2.5C8,2.2,7.8,2,7.5,2S7,2.2,7,2.5v0.6
		C6.4,3.3,6,3.8,6,4.5C6,5.3,6.7,6,7.5,6C7.8,6,8,6.2,8,6.5S7.8,7,7.5,7h-1C6.2,7,6,7.2,6,7.5S6.2,8,6.5,8H7v0.5C7,8.8,7.2,9,7.5,9
		S8,8.8,8,8.5V7.9c0.6-0.2,1-0.8,1-1.4C9,5.7,8.3,5,7.5,5z"/>
	<g>
		<path d="M17.5,3h-7C10.2,3,10,2.8,10,2.5S10.2,2,10.5,2h7C17.8,2,18,2.2,18,2.5S17.8,3,17.5,3z"/>
	</g>
	<g>
		<path d="M14.5,5h-4C10.2,5,10,4.8,10,4.5S10.2,4,10.5,4h4C14.8,4,15,4.2,15,4.5S14.8,5,14.5,5z"/>
	</g>
	<g>
		<path d="M14.5,7h-4C10.2,7,10,6.8,10,6.5S10.2,6,10.5,6h4C14.8,6,15,6.2,15,6.5S14.8,7,14.5,7z"/>
	</g>
	<g>
		<path d="M17.5,9h-7C10.2,9,10,8.8,10,8.5S10.2,8,10.5,8h7C17.8,8,18,8.2,18,8.5S17.8,9,17.5,9z"/>
	</g>
	<g>
		<path d="M13.5,12h-7C6.2,12,6,11.8,6,11.5S6.2,11,6.5,11h7c0.3,0,0.5,0.2,0.5,0.5S13.8,12,13.5,12z"/>
	</g>
	<g>
		<path d="M13.5,14h-4C9.2,14,9,13.8,9,13.5S9.2,13,9.5,13h4c0.3,0,0.5,0.2,0.5,0.5S13.8,14,13.5,14z"/>
	</g>
	<g>
		<path d="M13.5,16h-4C9.2,16,9,15.8,9,15.5S9.2,15,9.5,15h4c0.3,0,0.5,0.2,0.5,0.5S13.8,16,13.5,16z"/>
	</g>
	<g>
		<path d="M13.5,18h-7C6.2,18,6,17.8,6,17.5S6.2,17,6.5,17h7c0.3,0,0.5,0.2,0.5,0.5S13.8,18,13.5,18z"/>
	</g>
</g>
</svg>
                  <span>

                    مشاهده فاکتور
                  </span>
                </router-link>
              </p>
            </article>
          </div>
          <div class="notContent" v-else>
            <h3 class="fontsize-heavy">اطلاعاتی برای نمایش وجود ندارد</h3>
          </div>
          <table
            v-if="false"
            class="table table-responsive"
            style="display: table;"
          >
            <template v-if="orders.data.length != 0">
              <thead>
                <tr class="text-color-444 fontsize14">
                  <th scope="col">شناسه</th>
                  <th scope="col">هزینه ارسال</th>
                  <th scope="col">تخفیف</th>
                  <th scope="col">وضعیت</th>
                  <th scope="col">قیمت</th>
                  <th scope="col">تاریخ سفارش</th>
                  <th scope="col">جزئیات</th>
                </tr>
              </thead>
              <tbody v-if="orders != null">
                <tr
                  v-for="(order, index) in orders.data"
                  class="trHover"
                  :key="index"
                >
                  <td>{{ order.id }}</td>
                  <td>
                    <template v-if="order.shipping_amount == null"
                      >رایگان</template
                    >
                    <template v-else>{{
                      order.shipping_amount | price
                    }}</template>
                  </td>

                  <td>
                    <template v-if="order.discount_amount == 0"
                      >بدون تخفیف</template
                    >
                    <template v-else>{{
                      order.discount_amount | price
                    }}</template>
                  </td>
                  <td>
                    <OrderStatus :status="order.status" />
                  </td>
                  <td>
                    <template v-if="findPriceOrder(order.invoices) == null"
                      >رایگان</template
                    >
                    <template v-else>{{
                      findPriceOrder(order.invoices) | price
                    }}</template>
                  </td>
                  <td style="word-spacing: 6px">
                    {{ moment(order.created_at).format("HH:mm YYYY/MM/DD") }}
                  </td>
                  <td>
                    <router-link
                      :to="{ name: 'order', params: { id: order.id } }"
                    >
                      <button
                        class="showDetailBtn py-2 text-color-666 px-4 mr-3 border bg-none   fontsize14"
                      >
                        جزئیات <span class="go-to-icon"><ZNextSvg /></span>
                      </button>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </template>
            <div class="notContent" v-else>
              <h3 class="fontsize-heavy">اطلاعاتی برای نمایش وجود ندارد</h3>
            </div>
          </table>
        </div>

        <section
          id="mainPaginationBlog"
          v-if="orders && orders.last_page > 1"
          class="orders-list-pagination text-center mb-4 mt-0 p-2 bg-color-fa"
        >
          <section
            id="listPagination"
            class="list-pagination orders-list-pagination text-center py-2"
          >
            <pagination
              :limit="1"
              :data="orders"
              @pagination-change-page="changePage"
              :disabled="status"
              class="justify-content-center"
            >
              <span slot="prev-nav">
                <ZPrevSvg />
              </span>
              <span slot="next-nav">
                <ZNextSvg />
              </span>
            </pagination>
          </section>
        </section>
      </template>
    </section>
  </div>
</template>
<script>
import {
  findPayments,
  findPriceOrder,
  getInvoicesStatus,
  getSumPrice,
} from "@/libraries/functions";
import { Treeselect } from "@riophae/vue-treeselect";
import paginationMixin from "@/libraries/mixins/paginationMixin";
import LineGrow from "@/parts/Front/components/shared/LineGrow";
import OrderStatus from "@/parts/Front/components/panel/OrderStatus";
import ZPrevSvg from "@/parts/Front/components/shared/ZPrevSvg";
import ZNextSvg from "@/parts/Front/components/shared/ZNextSvg";

export default {
  inject: ["mediaQueries"],
  mixins: [paginationMixin],
  props: { tab: { Number } },
  data() {
    return {
      orderTrackingTab: 1,
      selectedOrder: "current",
     
    };
  },
  computed: {
    statistics() {
      return this.$store.getters["front/getOrderStatistics"];
    },
    // دریافت استاتوس وضعیت گرفتن سفارشات از سرور
    status() {
      return this.$store.getters["front/getOrdersInPanelStatus"];
    },
    // دریافت سفارشات از سرور
    orders() {
      return this.$store.getters["front/getOrdersInPanel"];
    },
  },
  created() {
    // اگر لیست سفارشات خالی بود از سرور گرفته شود
    if (this.orders == null) {
      let pack = {};
      if (this.$route.query.page) {
        pack.page = this.$route.query.page;
      } else {
        pack.page = 1;
      }
      if (this.$route.query.status != undefined) {
        pack.status = this.$route.query.status;
      } else {
        pack.status = "current";
      }
      this.$store.dispatch("front/getOrderInPanelFromServer", pack);
    }
  },
  watch: {
    selectedOrder(newVal) {
      if (newVal == "current") {
        this.orderTrackingTab = 1;
      }
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, status: newVal },
      });
    },
    // در صورت تغییر کوئری صقحه با چجینیشن درخواست جدید داده شود
    "$route.query.status": {
      handler: function(val) {
        const data = {
          status: val != undefined ? val : "current",
        };
        data.page =
          this.$route.query.page != undefined ? this.$route.query.pagee : "1";
        this.$store.dispatch("front/getOrderInPanelFromServer", data);
      },
    },
    "$route.query.page": {
      handler: function(val) {
        const data = {
          page: val != undefined ? val : "1",
        };

        data.status =
          this.$route.query.status != undefined
            ? this.$route.query.status
            : "current";

        this.$store.dispatch("front/getOrderInPanelFromServer", data);
      },
    },
  },
  methods: {
    findPayments,
    findPriceOrder,
    getInvoicesStatus,
    getSumPrice,
    changePage(page) {
      this.$nextTick(() => {
        document
          .querySelector(".in-user-panel")
          .scrollIntoView({ behavior: "smooth" });
      });
      if (this.disabled) {
        return;
      }
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, page: page },
      });
    },

    // با کلیک بروی دکمه ها خروجی سفارشات آن دسته نمایش داده میشود
    showOrders(orderTrackingTab, status) {
      this.orderTrackingTab = orderTrackingTab;
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, status: status },
      });
    },
  },
  mounted() {
    // بررسی کوئری برای نمایش دکمه مورد نظر در اولین برخورد
    let status = this.$route.query.status;
    if (this.$route.query.status != undefined) {
      if (status.toLowerCase() == "current") {
        this.orderTrackingTab = 1;
        this.selectedOrder = "current";
      } else {
        switch (status.toLowerCase()) {
          case "delivered":
            this.orderTrackingTab = 2;
            this.selectedOrder = "delivered";
            break;

          case "canceled":
            this.selectedOrder = "canceled";
            this.orderTrackingTab = 3;
            break;

      
        }
      }
    }
  },
  components: {
    ZNextSvg,
    ZPrevSvg,
    LineGrow,
    OrderStatus,
    Treeselect,
  },
};
</script>
<style scoped>
/* افزودن استایل به کدهای اضافه شده به قالب */
.skeleton {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 570px) {
  .skeleton {
    gap: 7px;
  }
}
.trHover:hover .showDetailBtn {
  background-color: #e8e8e8 !important;
}
.go-to-icon {
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translate(20px, -56%);
  opacity: 0;
  transition: 250ms all;
}
.showDetailBtn:hover .go-to-icon {
  opacity: 1;
  transform: translate(0px, -56%);
}

.showDetailBtn {
  position: relative;
  background-color: rgba(239, 239, 239, 0.9) !important;
  transition: 200ms all;
}
.showDetailBtn:hover {
  box-shadow: 0 0 10px -5px white;
}
.trHover {
  transition: all 0.5s;
  box-shadow: inset 0 0 19px -21px;
}
.trHover:hover {
  box-shadow: inset 0 0 19px -16px;
  /*background: #fcfcfc;*/
  background: #ffffff;
}
.notContent {
  margin-top: 4%;
  text-align: center;
}
.statusBtn {
  height: 37px;
  margin-top: 5px;
  transition: all 0.5s;
  border:none !important
}

#statusBtns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
}
#tabelBox,
div#tabelBox table tbody td {
  text-align: center;
}
div#tabelBox table thead th {
  color: white;
  text-align: center;
}
div#tabelBox table tbody tr:nth-child(even) {
  background: #48484847;
}
div#tabelBox table thead {
  background: black;
}
div#tabelBox table {
  border: 1px solid grey !important;
}
#orderSection {
  width: 100%;
  overflow: auto;
}
.statistics {
  position: relative;
  background-color: var(--color-theme3);
  right: 24px;
  min-width: 26px;
  height: 100%;
  line-height: 31px;
  display: inline-block;
  border-radius: 0 !important;
  font-size: 14px;
}
@media screen and (max-width: 570px) {
  .in-orders >>> .b-skeleton-button {
    width: 60px;
  }
}
@media screen and (max-width: 420px) {
  .in-orders >>> .b-skeleton-button {
    width: 40px;
  }
}

.in-orders >>> .pagination {
  margin-bottom: 0;
}

.orders-list-pagination {
  border-radius: 10px;
}

.order-tracking-active {
    background: linear-gradient(333deg, black, #3c1a5b) !important;
    color: white !important;
    border: unset !important;
    border-radius: 8px;
}
.statusBtn:hover {
  background: linear-gradient(333deg, black, #3c1a5b) !important;
    color: white !important;
    border: unset !important;
    border-radius: 8px;
}
/* new style */
p.headerOrder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0 16px;

}
.opacity06 {
    opacity: 0.6;
}
ul.mainOrderContent {
    display: flex;
    width: 100%;
    gap: 24px;
    align-items: center;
    padding:0 16px;
}
ul.mainOrderContent li::marker {
    opacity: 0.6;
    color:#00000047;

}

p.headerOrder span {
    min-width: max-content !important;
}
article.orderDetail {
    border: 1px solid #00000040;
    border-radius: 16px;
    padding: 16px 0;
    margin-bottom: 16px;
}
p.headerOrder a svg {
    width: 38px;
    height: 38px;
}

.mainOrderContent li:first-child{
  list-style-type: none;
}
.orderImagesSlider img {
    width: 80px;
}
.orderImagesSlider {
    border-top: 1px solid #00000040;
    border-bottom: 1px solid #00000040;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    gap: 16px;
    overflow: auto;
}
 .orderImagesSlider::-webkit-scrollbar {
  width:0;
  height: 0;
}
/*
#orderImagesSlider::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
#orderImagesSlider::-webkit-scrollbar-thumb {
  background-color: #3c1a5b;
  outline: 1px solid slategrey;
} */
p.footerContent {
    margin: 8px auto 0;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding: 0 16px;
}
p.footerContent a svg path {
    fill: #3c1a5b;;
}
p.footerContent a svg {
  transition: all 1.5s;
    width: 25px;
}
p.footerContent a span {
  transition: all 1s;
    min-width: max-content;
    font-size: 14px;
    font-family: 'yekan-bakh';
    font-weight: bold;
    color: #3c1a5b;;
}

p.footerContent a {
    display: flex;
    align-items: center;
    gap: 10px;
}
span.opacity1 {
    font-weight: bolder;
    font-family: 'yekan-bakh';
}
div#orderPageHeader {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
@media (max-width:768px){
  #orderPageHeader{
    flex-direction: column;
  }
  ul.mainOrderContent {
    flex-wrap: wrap;
  }
  ul.mainOrderContent li::marker {
    opacity: 0.6;
    color:white;

}
.mainTabSection{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.mainTabSection button{
  font-size: 14px;
    padding: 0 10px !important;
}

}
</style>
